import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DecisionDetailPage } from '../../decisions/shared/decision-detail/decision-detail.page';

@Component({
    selector: 'app-decision',
    templateUrl: './decision.component.html',
    styleUrls: ['./decision.component.scss'],
})
export class DecisionComponent {
    @Input() archive: any;

    constructor(public modalCtl: ModalController) {}

    async openDecisionDetailModal() {
        const modal = await this.modalCtl.create({
            component: DecisionDetailPage,
        });

        return await modal.present();
    }
}
