import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/start',
        pathMatch: 'full',
    },
    {
        path: 'walkthrough',
        loadChildren: () =>
            import('./walkthrough/walkthrough.module').then(
                (m) => m.WalkthroughPageModule,
            ),
    },
    {
        path: 'getting-started',
        loadChildren: () =>
            import('./getting-started/getting-started.module').then(
                (m) => m.GettingStartedPageModule,
            ),
    },
    {
        path: 'auth/start',
        loadChildren: () =>
            import('./authenticated-start/authenticated-start.module').then(
                (m) => m.AuthenticatedStartPageModule,
            ),
    },
    {
        path: 'auth/invite',
        loadChildren: () =>
            import('./login-invite/login-invite.module').then(
                (m) => m.LoginInvitePageModule,
            ),
    },

    {
        path: 'auth/forgot-password',
        loadChildren: () =>
            import('./forgot-password/forgot-password.module').then(
                (m) => m.ForgotPasswordPageModule,
            ),
    },
    {
        path: 'app',
        loadChildren: () =>
            import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    },
    {
        path: 'contact-card',
        loadChildren: () =>
            import('./contact-card/contact-card.module').then(
                (m) => m.ContactCardPageModule,
            ),
    },
    {
        path: 'forms-and-validations',
        loadChildren: () =>
            import('./forms/validations/forms-validations.module').then(
                (m) => m.FormsValidationsPageModule,
            ),
    },
    {
        path: 'forms-filters',
        loadChildren: () =>
            import('./forms/filters/forms-filters.module').then(
                (m) => m.FormsFiltersPageModule,
            ),
    },
    {
        path: 'page-not-found',
        loadChildren: () =>
            import('./page-not-found/page-not-found.module').then(
                (m) => m.PageNotFoundModule,
            ),
    },
    {
        path: 'showcase',
        loadChildren: () =>
            import('./showcase/showcase.module').then(
                (m) => m.ShowcasePageModule,
            ),
    },
    {
        path: 'firebase/crud',
        loadChildren: () =>
            import('./firebase/crud/firebase-crud.module').then(
                (m) => m.FirebaseCrudModule,
            ),
    },
    {
        path: 'firebase/auth',
        loadChildren: () =>
            import('./firebase/auth/firebase-auth.module').then(
                (m) => m.FirebaseAuthModule,
            ),
    },
    {
        path: 'maps',
        loadChildren: () =>
            import('./maps/maps.module').then((m) => m.MapsPageModule),
    },
    {
        path: 'video-playlist',
        loadChildren: () =>
            import('./video-playlist/video-playlist.module').then(
                (m) => m.VideoPlaylistPageModule,
            ),
    },

    {
        path: 'home',
        loadChildren: () =>
            import('./home/home.module').then((m) => m.HomePageModule),
    },
    {
        path: 'start',
        loadChildren: () =>
            import('./start/start.module').then((m) => m.StartPageModule),
    },
    // {
    //    path: '**',
    //    redirectTo: 'page-not-found'
    // },
    {
        path: 'expenses',
        loadChildren: () =>
            import('./expenses/expenses-main/expenses-main.module').then(
                (m) => m.ExpensesMainPageModule,
            ),
    },
    {
        path: 'calendar',
        loadChildren: () =>
            import('./calendar/calendar-main/calendar-main.module').then(
                (m) => m.CalendarMainPageModule,
            ),
    },

    {
        path: 'expenses-main',
        loadChildren: () =>
            import('./expenses/expenses-main/expenses-main.module').then(
                (m) => m.ExpensesMainPageModule,
            ),
    },

    {
        path: 'decisions',
        loadChildren: () =>
            import('./decisions/decisions-main/decisions-main.module').then(
                (m) => m.DecisionsMainPageModule,
            ),
    },
    {
        path: 'messages/detail',
        loadChildren: () =>
            import('./messages/message-detail/message-detail.module').then(
                (m) => m.MessageDetailPageModule,
            ),
    },
    {
        path: 'messages',
        loadChildren: () =>
            import('./messages/messages-main/messages-main.module').then(
                (m) => m.MessagesMainPageModule,
            ),
    },
    {
        path: 'login-invite',
        loadChildren: () =>
            import('./login-invite/login-invite.module').then(
                (m) => m.LoginInvitePageModule,
            ),
    },
];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
