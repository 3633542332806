import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ConstantsService {
    idUser: string = '';
    example: string = '';
    constructor() {}
}
