// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
    production: false,
    urlBase: 'https://nos-api.homologacao.etc.br',
    firebase: {
        apiKey: 'AIzaSyDB9m1cgLUpw0Z0nNW_Y37yO1woe6cm7k4',
        authDomain: 'osnossos-2020.firebaseapp.com',
        databaseURL: 'https://osnossos-2020.firebaseio.com',
        projectId: 'osnossos-2020',
        storageBucket: 'osnossos-2020.appspot.com',
        messagingSenderId: '1085565961888',
        appId: '1:1085565961888:web:8e5b80ee1ffcbf83d86bc5',
        measurementId: 'G-33TC9QGVH9',
    },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
