import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskIonicModule } from 'ngx-mask-ionic';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConstantsService } from './common/services/constants.service';
import { ModalAuthComponent } from './components/modal-auth/modal-auth.component';
import { DecisionComponent } from './components/decision/decision.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent, ModalAuthComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        NgxMaskIonicModule.forRoot(),
        ReactiveFormsModule,
        AppRoutingModule,
        ComponentsModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {
            enabled: environment.production,
        }),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],
    entryComponents: [DecisionComponent],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        ConstantsService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
