<ion-row>
    <ion-col class="ion-no-padding osn-card-list" size="12">
        <ion-card
            class="kid-02"
            *ngFor="let item of archive.items"
            (click)="openDecisionDetailModal()"
        >
            <span class="colored-border"></span>
            <ion-card-header>
                <ion-avatar
                    ><!-- avatar da criança -->
                    <img src="{{ item.avatar }}" />
                </ion-avatar>
                <p>
                    <!-- título da solicitação --><span>{{ item.title }}</span>
                </p>
            </ion-card-header>
            <ion-card-content>
                <!-- postado em --><span
                    >criado em 12/09 às 18:00<!--{{item.time}}--></span
                >
                <!-- badge de ação pendente --><ion-badge class="osn-green">
                    <img src="../../../assets/imgs/icons/check-osn.svg" />
                </ion-badge>
                <!-- ícone de categoria --><ion-avatar>
                    <img src="../../../assets/imgs/icons/decisions-osn.svg" />
                </ion-avatar>

                <!-- Foco da Discussão
                  <p>
                      <ion-chip>{{item.decision}}</ion-chip>
                  </p> -->
                <p><!-- descrição -->{{ item.description }}</p>
                <p class="osn-muted">
                    <!-- status -->
                    Decisão finalizada (aceito).
                </p>
            </ion-card-content>
        </ion-card>
    </ion-col>
</ion-row>
