import { Component } from '@angular/core';
import { Plugins } from '@capacitor/core';
const { SplashScreen } = Plugins;
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { HistoryHelperService } from './utils/history-helper.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: [
        './side-menu/styles/side-menu.scss',
        './side-menu/styles/side-menu.shell.scss',
        './side-menu/styles/side-menu.responsive.scss',
    ],
})
export class AppComponent {
    appOsNossosPages = [
        {
            title: 'Início',
            url: '/home',
            ionicIcon: 'home-outline',
        },
        {
            title: 'Mensagens',
            url: '/messages',
            ionicIcon: 'chatbubble-ellipses-outline',
        },
        {
            title: 'Calendário',
            url: '/calendar',
            ionicIcon: 'calendar-outline',
        },
        {
            title: 'Despesas',
            url: '/expenses',
            ionicIcon: 'wallet-outline',
        },

        {
            title: 'Decisões',
            url: '/decisions',
            ionicIcon: 'thumbs-up-outline',
        },
    ];
    accountOsNossosPages = [
        {
            title: 'Perfil',
            url: 'firebase/auth/profile',
            ionicIcon: 'person-circle-outline',
        },
        {
            title: 'Sair',
            url: '/auth/sigout',
            ionicIcon: 'log-out-outline',
        },
    ];
    appPages = [
        {
            title: 'Início',
            url: '/home',
            ionicIcon: 'home-outline',
        },
        {
            title: 'Categories',
            url: '/app/categories',
            ionicIcon: 'list-outline',
        },
        {
            title: 'Profile',
            url: '/app/user',
            ionicIcon: 'person-outline',
        },
        {
            title: 'Contact Card',
            url: '/contact-card',
            customIcon: './assets/custom-icons/side-menu/contact-card.svg',
        },
        {
            title: 'Notifications',
            url: '/app/notifications',
            ionicIcon: 'notifications-outline',
        },
    ];
    optionsOsNossosPages = [
        {
            title: 'Cadastro',
            url: '/',
            ionicIcon: 'people-circle-outline',
        },
    ];
    accountPages = [
        {
            title: 'Log In',
            url: '/firebase/auth/sign-in',
            ionicIcon: 'log-in-outline',
        },
        {
            title: 'Sign Up',
            url: '/firebase/auth/sign-up',
            ionicIcon: 'person-add-outline',
        },
        {
            title: 'Tutorial',
            url: '/walkthrough',
            ionicIcon: 'school-outline',
        },
        {
            title: 'Getting Started',
            url: '/getting-started',
            ionicIcon: 'rocket-outline',
        },
        {
            title: '404 page',
            url: '/page-not-found',
            ionicIcon: 'alert-circle-outline',
        },
    ];

    textDir = 'ltr';

    // Inject HistoryHelperService in the app.components.ts so its available app-wide
    constructor(
        public translate: TranslateService,
        public historyHelper: HistoryHelperService,
    ) {
        this.initializeApp();
        this.setLanguage();
    }

    async initializeApp() {
        try {
            await SplashScreen.hide();
        } catch (err) {
            console.log('This is normal in a browser', err);
        }
    }

    setLanguage() {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        this.translate.use('en');

        // this is to determine the text direction depending on the selected language
        // for the purpose of this example we determine that only arabic and hebrew are RTL.
        // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        //   this.textDir = (event.lang === 'ar' || event.lang === 'iw') ? 'rtl' : 'ltr';
        // });
    }
}
