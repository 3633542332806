import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-decision-detail',
    templateUrl: './decision-detail.page.html',
    styleUrls: ['./decision-detail.page.scss'],
})
export class DecisionDetailPage implements OnInit {
    constructor(private modalCtrl: ModalController) {}

    ngOnInit() {}

    public closeModal() {
        this.modalCtrl.dismiss();
    }
}
